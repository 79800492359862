import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`mdx example`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1a96f5ef0d9c3a35077537c934769f6c/e5166/smashburger.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgABBP/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAHpLMXsYL//xAAaEAACAwEBAAAAAAAAAAAAAAABAgADEhAT/9oACAEBAAEFArMrFcvMKT6V2Q8//8QAGREAAgMBAAAAAAAAAAAAAAAAAAERITFR/9oACAEDAQE/AU50rp//xAAaEQACAgMAAAAAAAAAAAAAAAAAAQIRISJR/9oACAECAQE/AZWng24f/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEhMSJRkf/aAAgBAQAGPwJbHSLRMMxRS6f/xAAdEAEAAgICAwAAAAAAAAAAAAABACERUTGBQXGR/9oACAEBAAE/IXwjaIhyl3qLydqzxr6IEMDh+yinuU//2gAMAwEAAgADAAAAEPjv/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAEDAQE/ECGpj//EABYRAQEBAAAAAAAAAAAAAAAAABEAAf/aAAgBAgEBPxDKMM3/AP/EAB8QAQEAAgEEAwAAAAAAAAAAAAERACFBMVFhcYGhwf/aAAgBAQABPxBl0ZoWxO25kAkCUPw4xJqu+RgDoGR29UxaQu97eb+u8plQJEv1n//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "my image",
            "title": "my image",
            "src": "/static/1a96f5ef0d9c3a35077537c934769f6c/e5166/smashburger.jpg",
            "srcSet": ["/static/1a96f5ef0d9c3a35077537c934769f6c/f93b5/smashburger.jpg 300w", "/static/1a96f5ef0d9c3a35077537c934769f6c/b4294/smashburger.jpg 600w", "/static/1a96f5ef0d9c3a35077537c934769f6c/e5166/smashburger.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`mdx example`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        }}>{`tets`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        }}></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        }}></a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      